export class RequestUtil {
    static async getFormToken (address: string) {
        return new Promise<Array<string>>((resolve, reject) => {
            let xhr = new XMLHttpRequest();

            xhr.addEventListener('load', () => {
                const values = JSON.parse(xhr.responseText);

                resolve([
                    values.fieldName,
                    values.tokenId,
                    values.token
                ]);
            });
            xhr.addEventListener('error', () => {
                reject(xhr.statusText);
            });
            xhr.open(
                'GET',
                address
            );
            xhr.send();
        });
    }

    static async postFormData (formData: FormData, address: string) {
        return new Promise<void>((resolve, reject) => {
            let xhr = new XMLHttpRequest();

            xhr.addEventListener('load', () => {
                if (xhr.status !== 200 && xhr.status !== 200) {
                    reject(new Error('Status code not OK: ' + xhr.statusText));
                }
                resolve();
            });
            xhr.addEventListener('error', () => {
                reject(new Error(xhr.statusText));
            });
            xhr.open(
                'POST',
                address
            );
            xhr.send(formData);
        });
    }
}
