























import { Component, Prop, Vue } from 'vue-property-decorator';
import TariffDetails from '../models/TariffDetails';
import InsuranceTariff from '@/models/InsuranceTariff';

@Component({})
export default class SelectedTariffCard extends Vue {
    /**
     * Gets the tariff details of the currently selected tariff
     */
    get selectedTariff (): TariffDetails | null {
        const selectedTariff: InsuranceTariff = this.$store.state.applicantData.chosenTariff;

        if (!selectedTariff) {
            return null;
        }
        return this.$store.state.tariffDetails.find(
            (tariff: TariffDetails) => selectedTariff.name.startsWith(tariff.identifier)
        );
    }
}
