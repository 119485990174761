






























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TariffDetails from '@/models/TariffDetails';
import InsuranceTariff from '@/models/InsuranceTariff';
import SelectedTariffCard from '@/components/SelectedTariffCard.vue';
import Applicant from '@/models/Applicant';
import { Actions } from '@/store';
import App from '@/App.vue';
import WizardStep from '@/models/WizardStep';
import Collapse from '@/components/Collapse.vue';
import { BankAccount } from '@/models/BankAccount';
import BankAccountForm from '@/components/BankAccountForm.vue';

@Component({
    components: {
        BankAccountForm,
        Collapse,
        SelectedTariffCard
    }
})
export default class WizardPagePersonalData extends Vue {
    @Prop() wizardStepId!: string;
    private wasValidatedOnce = false;
    private bankFormValid = false;

    get applicant (): Applicant {
        return this.$store.state.applicantData;
    }

    get salutation (): string {
        return this.$store.state.applicantData.salutation;
    }

    set salutation (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantSalutation, value);
    }

    get applicantTitle (): string {
        return this.$store.state.applicantData.title;
    }

    set applicantTitle (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantTitle, value);
    }

    get street (): string {
        return this.$store.state.applicantData.street;
    }

    set street (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantStreet, value);
    }

    get houseNumber (): string {
        return this.$store.state.applicantData.houseNo;
    }

    set houseNumber (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantHouseNumber, value);
    }

    get firstName (): string {
        return this.$store.state.applicantData.firstName;
    }

    set firstName (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantFirstName, value);
    }

    get lastName (): string {
        return this.$store.state.applicantData.lastName;
    }

    set lastName (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantLastName, value);
    }

    get postalCode (): string {
        return this.$store.state.applicantData.postalCode;
    }

    set postalCode (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantPostalCode, value);
    }

    get city (): string {
        return this.$store.state.applicantData.city;
    }

    set city (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantCity, value);
    }

    get birthName (): string {
        return this.$store.state.applicantData.birthName;
    }

    set birthName (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantBirthName, value);
    }

    get phoneNumber (): string {
        return this.$store.state.applicantData.phoneNumber;
    }

    set phoneNumber (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantPhoneNumber, value);
    }

    get emailAddress (): string {
        return this.$store.state.applicantData.emailAddress;
    }

    set emailAddress (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantEmail, value);
    }

    get paymentInterval (): string {
        return this.$store.state.applicantData.paymentInterval;
    }

    set paymentInterval (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantPaymentInterval, value);
    }

    get relationshipStatus (): string {
        return this.$store.state.applicantData.relationshipStatus;
    }

    set relationshipStatus (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantRelationshipStatus, value);
    }

    get showBankAccount (): boolean {
        return this.$store.state.applicantData.useBankAccount;
    }

    set showBankAccount (useAccount: boolean) {
        this.$store.dispatch(Actions.UpdateApplicantUseBankAccount, useAccount);
        this.checkValidity();
    }

    get pageValidity (): boolean {
        return this.$store.state.steps.find((step: WizardStep) => step.id === this.wizardStepId).isValid || false;
    }

    set pageValidity (isValid: boolean) {
        this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: isValid });
    }

    private onBankFormValidated (isValid: boolean): void {
        this.bankFormValid = isValid;
        this.checkValidity();
    }

    private checkValidity (): void {
        const form = this.$refs['form'] as HTMLFormElement;

        if (this.showBankAccount) {
            this.pageValidity = form.checkValidity() && this.bankFormValid;
        } else {
            this.pageValidity = form.checkValidity()
        }

        if (this.pageValidity && !this.wasValidatedOnce) {
            this.wasValidatedOnce = true;
        }
    }

    private getFieldValidityFor (name: string): boolean {
        const form = this.$refs['form'] as HTMLFormElement;
        return (form.elements.namedItem(name) as HTMLInputElement).validity.valid; // eslint-disable-line
    }
}
