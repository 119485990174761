











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LoadingOverlay extends Vue {
    get isLoading () {
        return this.$store.state.isLoading;
    }

    get message () {
        return this.$store.state.loadingText;
    }
}
