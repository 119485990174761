






























































import { Component, Prop, Vue } from 'vue-property-decorator'
import WizardStep from '../models/WizardStep';
import { Actions } from '@/store';
import NumberUtil from '@/utils/NumberUtil';
import InsuranceTariff from '@/models/InsuranceTariff';
import TariffUtil from '@/utils/TariffUtil';
import Child from '@/models/Child';

@Component
export default class ProgressWizard extends Vue {
    get steps (): Array<WizardStep> {
        const selectedTariff: InsuranceTariff|undefined = this.$store.state.applicantData.chosenTariff;

        return this.$store.state.steps
            .filter((step: WizardStep) => step.id !== 'result')
            .filter((step: WizardStep) => {
                if (!selectedTariff) {
                    return true;
                }

                if (step.id === 'healthQuery' && !!this.$store.state.children.length) {
                    return true;
                }

                if (step.tariffCondition.length === 0) {
                    return true;
                }

                return step.tariffCondition.some((condition: string) => {
                    return selectedTariff.name.includes(condition)
                });
            });
    }

    get currentStep (): string {
        return this.$store.state.currentStep;
    }

    set currentStep (stepId: string) {
        this.$store.dispatch(Actions.UpdateCurrentStep, stepId);
    }

    get calculatedInsuranceSumForDisplay (): string {
        return NumberUtil.toLocalizedNumberString(TariffUtil.CalculateMonthlySum(this.$store.state.applicantData));
    }

    get childCount (): number {
        const childs = this.$store.state.children || [];
        return childs.length;
    }

    get calculatedChildInsuranceSumForDisplay (): string {
        const childs = this.$store.state.children || [];
        const insuranceSum = childs.reduce((prev: number, curr: Child) => prev + TariffUtil.CalculateSumForChild(curr), 0);
        return NumberUtil.toLocalizedNumberString(insuranceSum);
    }

    get insuranceSumForDisplay (): string {
        return NumberUtil.toLocalizedNumberString(this.$store.state.applicantData.insuranceSum);
    }

    get isCurrentTariffMonthly (): boolean {
        return this.$store.state.applicantData.chosenTariff
            ? this.$store.state.applicantData.chosenTariff.isMonthly
            : false;
    }

    onStepSelected (ev: Event, step: string) {
        ev.preventDefault();
        this.currentStep = step;
    }
}
