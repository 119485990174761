





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TariffUtil from '../utils/TariffUtil';
import 'moment/locale/de';
import moment from 'moment';
import { InputFacade } from 'vue-input-facade';
import { Actions } from '@/store';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import RangeInput from '@/components/RangeInput.vue';

@Component({
    mixins: [validationMixin],
    components: { RangeInput, InputFacade },
    validations: {
        applicantBirthDate: {
            required
        }
    }
})
export default class WizardPageStart extends Vue {
    private lowestInsuranceSum = 500;
    private highestInsuranceSum = 8000;
    private insuranceSumStep = 500;
    private dateInvalid = false;
    private dateError = '';
    private birthDate = '';

    @Prop() wizardStepId!: string;

    /**
     * retrieves the current insurance start date from the store.
     */
    get insuranceStartDate (): string {
        return this.$store.state.applicantData.insuranceStart.toJSON();
    }

    /**
     * dispatches an action to update the current insurance start date
     * in the store.
     */
    set insuranceStartDate (value: string) {
        const newDate = moment(value).toDate();
        this.$store.dispatch(Actions.UpdateInsuranceStartDate, newDate);
    }

    /**
     * Uses the TariffUtil to compute the possible insurance start dates
     * for the current date and convert them into a displayable form.
     */
    get insuranceStartDates () {
        const startDates = TariffUtil.GetInsuranceStartDates();
        return startDates.map(startDate => {
            startDate.locale('de');
            return {
                display: startDate.format('DD. MMMM YYYY'),
                timestamp: startDate.toJSON()
            };
        });
    }

    get earliestAllowedBirthDate (): Date {
        return moment().startOf('month').subtract(18, 'years').toDate();
    }

    get oldestAllowedBirthdate (): Date {
        return moment().startOf('month').subtract(85, 'years').toDate();
    }

    /**
     * Retrieves the applicants birth date in form of a formatted string (YYYY-MM-dd) to be used
     * in an HTML-Input of type 'date'
     */
    get applicantBirthDate (): string {
        return this.birthDate;
    }

    set applicantBirthDate (value: string) {
        this.birthDate = value;

        // If no birthdate is entered or it is invalid
        const birthDate = moment(value, 'DDMMYYYY');
        if (!value || !birthDate.isValid()) {
            this.dateInvalid = true;
            this.dateError = 'Bitte geben Sie ein gültiges Datum ein!';
            this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: false });
            return;
        }

        if (this.isNotAllowedAge(birthDate.toDate())) {
            this.dateInvalid = true;
            this.dateError = 'Sie müssen zwischen 18 und 85 Jahre alt sein!';
            this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: false });
            return;
        }

        this.dateInvalid = false;

        this.setBirthdate(birthDate.toDate());
        this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: true });
    }

    /**
     * Retrieves the currently set applicants insurance sum from the store.
     */
    get applicantInsuranceSum (): number {
        return this.$store.state.applicantData.insuranceSum;
    }

    /**
     * Updates the applicants insurance sum in the store to a new value
     */
    set applicantInsuranceSum (value: number) {
        this.$store.dispatch(Actions.UpdateApplicantInsuranceSum, value);
    }

    isNotAllowedAge (date: Date): boolean {
        return date > this.earliestAllowedBirthDate || date < this.oldestAllowedBirthdate;
    }

    setBirthdate (date: Date | null): void {
        if (!date) {
            this.$store.dispatch(Actions.UpdateApplicantBirthDate, null);
            return;
        }
        const arr = moment(date).toArray();
        arr[1] += 1;

        // dispatch an action to update the store
        this.$store.dispatch(Actions.UpdateApplicantBirthDate, arr);
    }

    mounted (): void {
        const applicant = this.$store.state.applicantData;

        // check if there is already a birthdate stored (the user went back from another page)
        if (!applicant || applicant.birthDay === 0) {
            // if the birthdate was not supplied in the URL, return and leave the field blank.
            if (!window.location.search) {
                return;
            }

            // otherwise extract the birthdate from the URL and commit it to the store
            const searchWithoutQuestionMark = window.location.search.substring(1);
            const pairs = searchWithoutQuestionMark.split('&');
            if (pairs.length < 1) {
                return;
            }

            const birthdatePair = pairs.find(item => item.startsWith('birthdate'));
            if (!birthdatePair) {
                return;
            }

            const birthDate = moment(birthdatePair.split('=')[1]);

            this.setBirthdate(birthDate.toDate());
        }

        setTimeout(() => {
            // show the stored birthdate. This must be delayed because otherwise Vue does not get that the
            // calculated sum changed.
            this.birthDate = moment({
                years: applicant.birthYear,
                months: applicant.birthMonth - 1,
                days: applicant.birthDay
            })
                .format('DDMMYYYY');
        }, 500);
    }
}
