












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Collapse extends Vue {
    @Prop() id!: string;
    @Prop() title!: string;
    @Prop() startOpen!: boolean;
    @Prop() cssClass!: string;

    protected isExpanded: boolean = this.startOpen === undefined ? false : this.startOpen;

    toggleCollapseState () {
        this.isExpanded = !this.isExpanded;
    }
}
