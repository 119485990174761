















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { BankAccount } from '@/models/BankAccount';
import { Actions } from '@/store';
import { electronicFormatIBAN, isValidBIC, isValidIBAN } from 'ibantools';
import Applicant from '@/models/Applicant';

@Component
export default class BankAccountForm extends Vue {
    private bankAccount: BankAccount = {
        bank: '',
        iban: '',
        bic: '',
        city: '',
        postalCode: '',
        houseNumber: '',
        street: '',
        firstName: '',
        lastName: ''
    } as BankAccount;
    private isIBANValid = false;
    private isBICValid = false;
    private isBankValid = false;
    private isFirstNameValid = false;
    private isLastNameValid = false;
    private isStreetValid = false;
    private isHouseNumberValid = false;
    private isPostalValid = false;
    private isCityValid = false;
    private hasBeenValidated = false;

    mounted (): void {
        this.bankAccount = this.$store.state.applicantData.bankAccount;

        // if name is not set, prefill with applicant data
        if (!this.bankAccount.firstName && !this.bankAccount.lastName) {
            const applicantData: Applicant = this.$store.state.applicantData;

            this.bankAccount.firstName = applicantData.firstName;
            this.bankAccount.lastName = applicantData.lastName;
            this.bankAccount.street = applicantData.street;
            this.bankAccount.houseNumber = applicantData.houseNo;
            this.bankAccount.postalCode = applicantData.postalCode;
            this.bankAccount.city = applicantData.city;
        }

        this.$store.watch(state => state.applicantData.bankAccount,
            (value: BankAccount) => {
                this.bankAccount = value
            });
    }

    @Watch('bankAccount', { deep: true })
    onBankAccountChanged (value: BankAccount, oldValue: BankAccount) {
        if (!!this.bankAccount.iban && this.bankAccount.iban.includes(' ')) {
            const ibanNoSpaces = electronicFormatIBAN(this.bankAccount.iban);
            if (ibanNoSpaces) {
                this.bankAccount.iban = ibanNoSpaces;
            }
        }
        const ibanValid = isValidIBAN(this.bankAccount.iban);

        if (!!this.bankAccount.iban && ibanValid && !this.bankAccount.bic) {
            this.$store.dispatch(Actions.FetchBankInfoFromIBAN, this.bankAccount.iban);
        }
        this.$emit('validate', this.validate())
        this.$store.dispatch(Actions.UpdateApplicantBankAccount, value);
    }

    private validate (): boolean {
        this.hasBeenValidated = true;
        this.isIBANValid = isValidIBAN(this.bankAccount.iban);
        this.isBICValid = isValidBIC(this.bankAccount.bic);
        this.isBankValid = !!this.bankAccount.bank && this.bankAccount.bank.length > 2;
        this.isFirstNameValid = !!this.bankAccount.firstName && this.bankAccount.firstName.length > 1;
        this.isLastNameValid = !!this.bankAccount.lastName && this.bankAccount.lastName.length > 1;
        this.isStreetValid = !!this.bankAccount.street && this.bankAccount.street.length > 1;
        this.isHouseNumberValid = !!this.bankAccount.houseNumber && this.bankAccount.houseNumber !== '0';
        this.isPostalValid = !!this.bankAccount.postalCode && this.bankAccount.postalCode.length > 1;
        this.isCityValid = !!this.bankAccount.city && this.bankAccount.city.length > 1;
        return this.isIBANValid &&
            this.isBICValid &&
            this.isBankValid &&
            this.isFirstNameValid &&
            this.isLastNameValid &&
            this.isStreetValid &&
            this.isHouseNumberValid &&
            this.isPostalValid &&
            this.isCityValid;
    }
}
