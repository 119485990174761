import Applicant from './Applicant';
import Child from './Child';
import InsuranceTariff from './InsuranceTariff';
import TariffDetails from './TariffDetails';
import WizardStep from './WizardStep';

export default class StoreState {
    public exhibitionMode: boolean;
    public isLoading: boolean;
    public calculatedSum: number;
    public currentStep: string;
    public steps: Array<WizardStep>;
    public loadingText: string;
    public tariffs: Map<string, InsuranceTariff>;
    public children: Array<Child>;
    public tariffDetails: Array<TariffDetails>;
    public applicantData: Applicant;
    public comment: string = '';
    public brokerId: string = '';
    public brokerMail: string = '';
    public poolBrokerId: string|null = null;
    public financeAdsId: string|null = null;
    public sendFormsViaMail: boolean = false;

    constructor (exhibitionMode = false, brokerId: string, brokerMail: string, financeAds: string|null = null,
        poolBrokerId: string|null = null) {
        this.exhibitionMode = exhibitionMode;
        this.brokerId = brokerId;
        this.brokerMail = brokerMail;
        this.poolBrokerId = poolBrokerId;
        this.financeAdsId = financeAds;
        this.isLoading = true;
        this.calculatedSum = 0;
        this.loadingText = 'Anwendung wird geladen...';
        this.tariffs = new Map<string, InsuranceTariff>();
        this.children = [];
        this.tariffDetails = [
            {
                identifier: 'C03',
                isVisible: true,
                isSelected: false,
                isPreSelected: false,
                minAge: 18,
                maxAge: 70,
                title: 'Laufender Beitrag',
                subtitle: '(sofortiger Versicherungsschutz)',
                details: [
                    'mit laufender Beitragszahlung',
                    'Versicherungssummen 500 € - 8.000 €',
                    'Gesundheitsabfrage',
                    'sofortigem Versicherungsschutz',
                    'Eintrittsalter <strong>18-70 Jahre</strong>'
                ],
                optionsExpanded: false,
                options: [
                    {
                        identifier: '50',
                        title: '50. Lebensjahr',
                        allowedForAge: true
                    },
                    {
                        identifier: '65',
                        title: '65. Lebensjahr',
                        allowedForAge: true
                    },
                    {
                        identifier: '70',
                        title: '70. Lebensjahr',
                        allowedForAge: true
                    },
                    {
                        identifier: '85',
                        title: '85. Lebensjahr',
                        allowedForAge: true
                    }
                ]
            },
            {
                identifier: 'C0485',
                isVisible: true,
                isSelected: false,
                isPreSelected: false,
                minAge: 18,
                maxAge: 70,
                title: 'Laufender Beitrag',
                subtitle: '(ohne Gesundheitsabfrage)',
                details: [
                    'mit laufender Beitragszahlung',
                    'Versicherungssummen 500 € - 8.000 €',
                    'Ohne Gesundheitsabfrage',
                    '3-jährige Wartezeit - <i>gestaffelte Leistung innerhalb der Wartezeit</i>',
                    'Eintrittsalter <strong>18-70 Jahre</strong>',
                    'Beitragszahlung bis zum 85. Lebensjahr'
                ],
                optionsExpanded: false,
                options: [
                ]
            },
            {
                identifier: 'E03',
                isVisible: true,
                isSelected: false,
                isPreSelected: false,
                minAge: 50,
                maxAge: 85,
                title: 'Einmalbeitrag',
                subtitle: '',
                details: [
                    'Tarif mit Einmalbetrag',
                    'Ohne Gesundheitsabfrage',
                    '3-jährige Wartezeit - <i>gestaffelte Leistung innerhalb der Wartezeit</i>',
                    'Eintrittsalter <strong>50-85 Jahre</strong>'
                ],
                optionsExpanded: false,
                options: [
                ]
            }
        ];
        this.applicantData = new Applicant();

        this.steps = [
            {
                id: 'start',
                title: 'Ihre Angaben',
                isValid: false,
                tariffCondition: []
            },
            {
                id: 'tariffChoice',
                title: 'Ihre Tarifwahl',
                isValid: true,
                tariffCondition: []
            },
            {
                id: 'children',
                title: 'Kinder versichern',
                isValid: true,
                tariffCondition: []
            }
        ];

        if (!exhibitionMode) {
            // collect personal data when not in exhibition mode
            this.steps.push(
                {
                    id: 'personalData',
                    title: 'Persönliche Daten',
                    isValid: false,
                    tariffCondition: []
                },
                {
                    id: 'healthQuery',
                    title: 'Gesundheit',
                    isValid: false,
                    tariffCondition: [
                        'C03'
                    ]
                }
            );
        }

        this.steps.push(
            {
                id: 'result',
                title: 'Ihr Beitrag',
                isValid: false,
                tariffCondition: []
            }
        );
        this.currentStep = this.steps[0].id;
    }
}
