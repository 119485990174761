

























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Collapse from './Collapse.vue';
import { InputFacade } from 'vue-input-facade';
import SelectedTariffCard from '@/components/SelectedTariffCard.vue';
import { Actions } from '@/store';

@Component({
    components: {
        SelectedTariffCard,
        Collapse,
        InputFacade
    }
})
export default class WizardPageHealthQuery extends Vue {
    @Prop({ default: '' }) wizardStepId!: string;

    private showCheckHealthQueryOption1: boolean = false;
    private showCheckHealthQueryOption2: boolean = false;
    private showCheckDeathQueryOption1: boolean = false;
    private showCheckDeathQueryOption2: boolean = false;

    private get isC03 (): boolean {
        return (this.$store.state.applicantData.chosenTariff &&
            this.$store.state.applicantData.chosenTariff.name.startsWith('C03'));
    }

    private get healthy (): string {
        return this.$store.state.applicantData.healthy !== null ? this.$store.state.applicantData.healthy.toString() : '';
    }

    private set healthy (value: string) {
        this.$store.dispatch(Actions.UpdateApplicantHealthStatus, value === 'true');
    }

    private get healthInfo (): string {
        return this.$store.state.applicantData.healthInfo;
    }

    private set healthInfo (newInfo: string) {
        this.$store.dispatch(Actions.UpdateApplicantHealthInfo, newInfo);
    }

    private get healthQueryOption (): string {
        return this.$store.state.applicantData.healthQueryOption;
    }

    private set healthQueryOption (option: string) {
        this.$store.dispatch(Actions.UpdateApplicantHealthQueryOption, option);
    }

    private get deathQueryOption (): string {
        return this.$store.state.applicantData.deathQueryOption;
    }

    private set deathQueryOption (option: string) {
        this.$store.dispatch(Actions.UpdateApplicantDeathQueryOption, option);
    }

    private checkValidity (): void {
        if ((this.healthy === 'true' && this.healthQueryOption !== '' && this.deathQueryOption !== '') ||
            (this.healthy === 'false' && this.healthInfo !== '' && this.healthQueryOption !== '' && this.deathQueryOption !== '')) {
            this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: true });
            return;
        }
        this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: false });
    }
}
