








































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TariffUtil from '../utils/TariffUtil';
import Applicant from '../models/Applicant';
import TariffDetails from '../models/TariffDetails';
import { Actions } from '../store';
import StoreState from '../models/StoreState';
import TariffOption from '../models/TariffOption';
import InsuranceTariff from '@/models/InsuranceTariff';

@Component
export default class WizardPageTariffChoice extends Vue {
    @Prop({ default: '' }) wizardStepId!: string;

    private _chosenTariffOption: string = '';
    private showWarning = false;

    get tariffDetails (): Array<TariffDetails> {
        const tariffDetails: Array<TariffDetails> = this.$store.state.tariffDetails;
        const applicantAge: number = this.$store.state.applicantData.age;
        return tariffDetails.map(tariff => {
            // Disable all tariffs,
            tariff.isVisible = false;

            // then check which tariffs the applicant is allowed to choose
            // and set them to visible again
            if (applicantAge >= tariff.minAge && applicantAge <= tariff.maxAge) {
                tariff.isVisible = true;
            }

            return tariff;
        });
    }

    get chosenTariff (): string {
        return this.$store.state.applicantData.chosenTariff
            ? this.$store.state.applicantData.chosenTariff.name
            : '';
    }

    get showInfo (): boolean {
        return this.tariffDetails.filter(t => t.isSelected && t.options.length > 0).length > 0;
    }

    public onAlertClose (event: Event) {
        event.preventDefault();
        this.showWarning = false;
    }

    public onTariffSelected (tariff: TariffDetails) {
        // hide warning if any tariff is selected
        this.showWarning = false;
        // expand or collapse options if there are any
        if (tariff.options.length > 0) {
            tariff.optionsExpanded = !tariff.optionsExpanded;
            // show warning if tariff with options is selected
            this.showWarning = true;
            this.setTariffSelected(tariff);

            return;
        }

        const state = this.$store.state as StoreState;
        const tariffInstance = state.tariffs.get(tariff.identifier);
        this.setTariffSelected(tariff);

        this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: true });
        this.$store.dispatch(Actions.UpdateApplicantChosenTariff, tariffInstance);
    }

    public onTariffOptionSelected (tariff: TariffDetails, tariffOption: TariffOption) {
        const state = this.$store.state as StoreState;
        const tariffInstance = state.tariffs.get(tariff.identifier + tariffOption.identifier);

        this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: true });
        this.$store.dispatch(Actions.UpdateApplicantChosenTariff, tariffInstance);
    }

    public setTariffSelected (tariff: TariffDetails) {
        this.tariffDetails.forEach(tariffDetail => {
            tariffDetail.isSelected = false;
            tariffDetail.isPreSelected = false;
            tariffDetail.optionsExpanded = false;
        });

        tariff.isSelected = true;

        if (tariff.options.length > 0) {
            tariff.optionsExpanded = true;
        }
    }

    public getOptionsForTariff (tariff: TariffDetails): Array<TariffOption> {
        return tariff.options.map(option => {
            if (TariffUtil.includesTariffOptionForAge(
                this.$store.state.applicantData.age,
                tariff, option,
                this.$store.state.tariffs
            )) {
                option.allowedForAge = true;
                return option;
            }

            option.allowedForAge = false;
            return option;
        })
    }
}
