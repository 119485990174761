












































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ProgressWizard from './components/ProgressWizard.vue';
import LoadingOverlay from './components/LoadingOverlay.vue';
import WizardPageStart from './components/WizardPageStart.vue';
import WizardPageTariffChoice from './components/WizardPageTariffChoice.vue';
import WizardPageResult from './components/WizardPageResult.vue';
import WizardPageChildren from './components/WizardPageChildren.vue';
import createStore, { Actions } from '@/store';
import Applicant from './models/Applicant';
import WizardStep from './models/WizardStep';
import printJS from 'print-js';
import WizardPagePersonalData from '@/components/WizardPagePersonalData.vue';
import WizardPageHealthQuery from '@/components/WizardPageHealthQuery.vue';
import InsuranceTariff from '@/models/InsuranceTariff';
import TariffUtil from './utils/TariffUtil';

@Component({
    store: createStore(),
    components: {
        WizardPageHealthQuery,
        WizardPagePersonalData,
        ProgressWizard,
        LoadingOverlay,
        WizardPageStart,
        WizardPageTariffChoice,
        WizardPageChildren,
        WizardPageResult
    }
})
export default class App extends Vue {
    get exhibitionMode (): boolean {
        return this.$store.state.exhibitionMode;
    }

    get wizardSteps (): Array<WizardStep> {
        return this.$store.state.steps;
    }

    get currentWizardStep (): string {
        return this.$store.state.currentStep;
    }

    get currentWizardStepIndex (): number {
        return this.wizardSteps.findIndex(step => step.id === this.currentWizardStep);
    }

    get isLoading (): boolean {
        return this.$store.state.isLoading;
    }

    get applicant (): Applicant {
        return this.$store.state.applicantData;
    }

    get enableNextStepBtn (): boolean {
        const currStepIdx = this.currentWizardStepIndex;
        if (!TariffUtil.isCurrentTariffAllowedForAge(this.$store.state.applicantData) && currStepIdx > 0) {
            return false;
        }
        return this.$store.state.steps[this.currentWizardStepIndex].isValid;
    }

    set applicant (value: Applicant) {
        this.$store.dispatch('updateApplicant', value);
    }

    public created () {
        this.$store.dispatch(Actions.FetchTariffs).then(() => {
            this.$store.dispatch(Actions.DisableLoadingStatus);
        });
    }

    onBtnNextClick (event: Event) {
        event.preventDefault();

        const currStepIdx = this.currentWizardStepIndex;

        if (currStepIdx < 0 || currStepIdx > this.wizardSteps.length) {
            return;
        }

        if (!TariffUtil.isCurrentTariffAllowedForAge(this.$store.state.applicantData) && currStepIdx > 0) {
            return;
        }

        const selectedTariff: InsuranceTariff|undefined = this.$store.state.applicantData.chosenTariff;
        const nextStep = this.wizardSteps.find((step: WizardStep, index: number) => {
            // return the next step that matches with its conditions
            if (index <= currStepIdx) {
                return false;
            }

            if (!selectedTariff) {
                return true;
            }

            if (step.id === 'healthQuery' && !!this.$store.state.children.length) {
                return true;
            }

            if (step.tariffCondition.length === 0) {
                return true;
            }

            return step.tariffCondition.some((condition: string) => {
                return selectedTariff.name.includes(condition)
            });
        });

        if (nextStep) {
            this.$store.dispatch(Actions.UpdateCurrentStep, nextStep.id);
            return;
        }
        // eslint-disable-next-line
        console.warn('[InsuranceCalculator] Next step is null!');
    }

    onBtnPreviousClick (event: Event) {
        event.preventDefault();

        const currStepIdx = this.currentWizardStepIndex;

        if (currStepIdx < 0 || currStepIdx > this.wizardSteps.length) {
            return;
        }

        const selectedTariff: InsuranceTariff|undefined = this.$store.state.applicantData.chosenTariff;

        // reverse the loop and return the next step with an index smaller than the current one
        // and that matches the conditions
        for (let i = currStepIdx - 1; i >= 0; i--) {
            const wizardStep = this.wizardSteps[i];

            if (i < currStepIdx && !wizardStep.tariffCondition.some((condition: string) => selectedTariff
                ? !selectedTariff.name.includes(condition)
                : true)) {
                this.$store.dispatch(Actions.UpdateCurrentStep, this.wizardSteps[i].id);
                return;
            }
        }
    }

    onPrintBtnClick (event: Event) {
        event.preventDefault();

        printJS({
            printable: this.getPrintableData(this.applicant),
            properties: [
                { field: 'applicantData', displayName: 'Antragsteller' },
                { field: 'chosenTariff', displayName: 'Tarif' }
            ],
            type: 'json'
        });
    }

    onBtnSubmitClick (event: Event) {
        event.preventDefault();
        if (!TariffUtil.isCurrentTariffAllowedForAge(this.$store.state.applicantData)) {
            return;
        }
        this.$store.dispatch(Actions.EnableLoadingStatus, 'Antragsdaten werden übermittelt.');
        this.$store.dispatch(Actions.SubmitApplication);
    }

    getPrintableData (applicantData: Applicant): Array<any> {
        return [
            {
                applicantData: ['test'],
                chosenTariff: applicantData.chosenTariff
            }
        ];
    }

    reloadPage (event: Event) {
        event.preventDefault();

        window.location.reload();
    }
}
