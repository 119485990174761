import { render, staticRenderFns } from "./WizardPageHealthQuery.vue?vue&type=template&id=475fbcd5&scoped=true&"
import script from "./WizardPageHealthQuery.vue?vue&type=script&lang=ts&"
export * from "./WizardPageHealthQuery.vue?vue&type=script&lang=ts&"
import style0 from "./WizardPageHealthQuery.vue?vue&type=style&index=0&id=475fbcd5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "475fbcd5",
  null
  
)

export default component.exports