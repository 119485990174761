

































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TariffUtil from '../utils/TariffUtil';
import 'moment/locale/de';
import moment from 'moment';
import TariffDetails from '../models/TariffDetails';
import InsuranceTariff from '../models/InsuranceTariff';
import Child from '@/models/Child';
import NumberUtil from '@/utils/NumberUtil';
import SelectedTariffCard from '@/components/SelectedTariffCard.vue';
import { Actions } from '@/store';
import Applicant from '@/models/Applicant';
@Component({
    components: { SelectedTariffCard }
})
export default class WizardPageResult extends Vue {
    @Prop()
    public wizardStepId!: string;

    get applicant (): Applicant {
        return this.$store.state.applicantData;
    }

    get privacyAccepted (): boolean {
        return this.applicant.privacyAccepted;
    }

    set privacyAccepted (value: boolean) {
        this.$store.dispatch(Actions.UpdatePrivacyAccepted, { accepted: value });
    }

    get infosRead (): boolean {
        return this.applicant.infosRead;
    }

    set infosRead (value: boolean) {
        this.$store.dispatch(Actions.UpdateInfosRead, { accepted: value });
    }
    get toldTheTruth (): boolean {
        return this.applicant.toldTheTruth;
    }

    set toldTheTruth (value: boolean) {
        this.$store.dispatch(Actions.UpdateToldTheTruth, { accepted: value });
    }
    get confirmDocumentsAccess (): boolean {
        return this.applicant.confirmDocumentsAccess;
    }

    set confirmDocumentsAccess (value: boolean) {
        this.$store.dispatch(Actions.UpdateConfirmDocumentsAccess, { accepted: value });
    }

    get adConsentOffline (): boolean {
        return this.applicant.adConsentOffline;
    }

    set adConsentOffline (value: boolean) {
        this.$store.dispatch(Actions.UpdateAdConsent, { offline: value, online: this.adConsentOnline });
    }

    get adConsentOnline (): boolean {
        return this.applicant.adConsentOnline;
    }

    set adConsentOnline (value: boolean) {
        this.$store.dispatch(Actions.UpdateAdConsent, { offline: this.adConsentOffline, online: value });
    }

    /**
     * retrieves the current insurance start date from the store.
     */
    get insuranceStartDate (): string {
        return this.applicant.insuranceStart.toJSON();
    }

    get isMonthlyFee (): boolean {
        return this.applicant.chosenTariff ? this.applicant.chosenTariff.isMonthly : true;
    }

    /**
     * Retrieves the applicants birth date in form of a formatted string (YYYY-MM-dd) to be used
     * in an HTML-Input of type 'date'
     */
    get applicantBirthDate (): Date {
        return moment([this.applicant.birthYear, this.applicant.birthMonth - 1, this.applicant.birthDay]).toDate();
    }

    get applicantChosenTariffFee (): string {
        const applicantTariffFee = TariffUtil.CalculateMonthlySum(this.applicant);
        return NumberUtil.toLocalizedNumberString(applicantTariffFee);
    }

    /**
     * Retrieves the currently set applicants insurance sum from the store.
    */
    get applicantInsuranceSum (): string {
        return NumberUtil.toLocalizedNumberStringNoFractions(this.applicant.insuranceSum);
    }

    get children (): Array<Child> {
        return this.$store.state.children;
    }

    /**
     * Gets the tariff details of the currently selected tariff
     */
    get selectedTariff (): TariffDetails|null {
        const selectedTariff: InsuranceTariff = this.applicant.chosenTariff as InsuranceTariff;
        if (!selectedTariff) {
            return null;
        }
        return this.$store.state.tariffDetails.find(
            (tariff: TariffDetails) => selectedTariff.name.startsWith(tariff.identifier)
        );
    }

    /**
     * Gets the calculated insurance sum for displaying it with the decimal separator already
     * replaced with comma and rounded to two fractions
     */
    get calculatedInsuranceSumForDisplay (): string {
        return NumberUtil.toLocalizedNumberString(this.$store.state.calculatedSum);
    }

    /**
     * Returns the age until the applicant needs to pay the fee
     */
    get paymentUntilAge (): number {
        // the option is appended to the main tariff identifier
        // (name=C0385 => tariff=C03, option=85). The tariff is always 3 characters long,
        // so we can assume that everything past the three characters is an option.
        const option = this.applicant.chosenTariff ? this.applicant.chosenTariff.name.slice(3) : '0';

        return parseInt(option);
    }

    get exhibitionMode (): boolean {
        return this.$store.state.exhibitionMode;
    }

    get sendFormsViaMail (): boolean {
        return this.$store.state.sendFormsViaMail;
    }

    set sendFormsViaMail (value: boolean) {
        this.$store.dispatch(Actions.SetSendFormsViaMail, value);
    }

    get comment (): string {
        return this.$store.state.comment;
    }

    set comment (value: string) {
        this.$store.dispatch(Actions.UpdateComment, value);
    }

    public getCalculatedChildSum (child: Child): string {
        const childSum = TariffUtil.CalculateSumForChild(child);
        return NumberUtil.toLocalizedNumberString(childSum);
    }

    /**
     * Returns the insurance sum for the specified child formatted
     * as localized string.
     * @param child
     */
    public insuranceSumForChild (child: Child): string {
        return NumberUtil.toLocalizedNumberStringNoFractions(child.insuranceSum);
    }

    private determineValidity (): void {
        if (this.privacyAccepted && this.confirmDocumentsAccess && this.infosRead && this.toldTheTruth) {
            this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: true })
            return;
        }
        this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: false })
    }
}
