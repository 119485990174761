




















import { Component, Prop, Vue } from 'vue-property-decorator';
import NumberUtil from '@/utils/NumberUtil';

@Component
export default class RangeInput extends Vue {
    @Prop() id!: string;
    @Prop() name!: string;
    @Prop() min!: number;
    @Prop() max!: number;
    @Prop() value!: number;
    @Prop() step!: number;

    get minValueAsString (): string {
        return NumberUtil.toLocalizedNumberStringNoFractions(this.min);
    }

    get maxValueAsString (): string {
        return NumberUtil.toLocalizedNumberStringNoFractions(this.max);
    }

    calculateValueBubble () {
        const control = (this.$refs['element'] as HTMLElement).querySelector('#' + this.id) as HTMLInputElement;

        if (control === null) {
            return;
        }

        const controlMin = parseInt(control.min);
        const controlMax = parseInt(control.max);
        const controlVal = control.valueAsNumber ? control.valueAsNumber : this.value;
        const controlThumbWidth = 20;

        const range = controlMax - controlMin;
        const position = ((controlVal - controlMin) / range) * 100;
        const positionOffset = Math.round(controlThumbWidth * position / 100) - (controlThumbWidth / 2);
        const output = (this.$refs['element'] as HTMLElement).querySelector(`#value-bubble__${this.id}`) as HTMLElement;

        if (output === null) {
            return;
        }

        output.style.left = `calc(${position}% - ${positionOffset}px)`;
        output.innerText = `${NumberUtil.toLocalizedNumberStringNoFractions(controlVal)} €`;
    }

    onRangeInput (event: Event) {
        if (event.target === null) {
            return;
        }

        const inputElem = (event.target as HTMLInputElement);
        if (inputElem === null) {
            return;
        }

        this.calculateValueBubble();
        this.$emit('input', parseInt(inputElem.value));
    }

    public mounted () {
        this.calculateValueBubble();
    }
}
