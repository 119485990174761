import TariffUtil from '@/utils/TariffUtil';
import moment from 'moment';
import InsuranceTariff from './InsuranceTariff';
import { BankAccount } from '@/models/BankAccount';

export default class Applicant {
    /** Birthdate */
    private _birthDay: number = 0;
    private _birthMonth: number = 0;
    private _birthYear: number = 0;

    /** Insurance */
    private _insuranceSum: number = 5000.00;
    private _insuranceStart: Date;

    /** Selected Tariff */
    private _chosenTariff: InsuranceTariff | undefined;

    /** Personal data */
    private _salutation: string = '';
    private _title: string = '';
    private _firstName: string = '';
    private _lastName: string = '';
    private _birthName: string = '';
    private _street: string = '';
    private _houseNo: string = '';
    private _postalCode: string = '';
    private _city: string = '';
    private _phoneNumber: string = '';
    private _emailAddress: string = '';
    private _paymentInterval: string = '';
    private _relationshipStatus: string = '';
    private _bankAccount: BankAccount;

    /** Health data query data **/
    private _healthy: boolean|null = null;
    private _healthInfo: string = '';
    private _healthQueryOption: string = '';
    private _deathQueryOption: string = '';

    public useBankAccount: boolean = false;
    public adConsentOffline: boolean = false;
    public adConsentOnline: boolean = false;

    public privacyAccepted: boolean = false;
    public infosRead: boolean = false;
    public toldTheTruth: boolean = false;
    public confirmDocumentsAccess: boolean = false;

    /**
     * Getter age
     */
    public get age (): number {
        const insuranceStart = moment(this.insuranceStart);
        const dateOfBirth = moment([
            this._birthYear,
            this._birthMonth,
            this._birthDay
        ]);

        const exactAge = insuranceStart.diff(dateOfBirth, 'years', true);
        const commerciallyRoundedAge = Math.round(exactAge);

        return commerciallyRoundedAge;
    }

    /**
     * Getter birthDay
     * @return {number}
     */
    public get birthDay (): number {
        return this._birthDay;
    }

    /**
     * Getter birthMonth
     * @return {number }
     */
    public get birthMonth (): number {
        return this._birthMonth + 1;
    }

    /**
     * Getter birthYear
     * @return {number }
     */
    public get birthYear (): number {
        return this._birthYear;
    }

    /**
     * Getter insuranceSum
     * @return {number }
     */
    public get insuranceSum (): number {
        return this._insuranceSum;
    }

    /**
     * Getter insuranceStart
     * @return {Date}
     */
    public get insuranceStart (): Date {
        return this._insuranceStart;
    }

    /**
     * Getter chosenTariff
     * @return {InsuranceTariff }
     */
    public get chosenTariff (): InsuranceTariff | undefined {
        return this._chosenTariff;
    }

    /**
     * Getter gender
     * @return {string }
     */
    public get salutation (): string {
        return this._salutation;
    }

    /**
     * Getter title
     * @return {string }
     */
    public get title (): string {
        return this._title;
    }

    /**
     * Getter firstName
     * @return {string }
     */
    public get firstName (): string {
        return this._firstName;
    }

    /**
     * Getter lastName
     * @return {string }
     */
    public get lastName (): string {
        return this._lastName;
    }

    public get lastNameNoUmlauts (): string {
        return this._lastName
            .replace('ä', 'ae')
            .replace('ö', 'oe')
            .replace('ü', 'ue')
            .replace('Ä', 'ae')
            .replace('Ö', 'oe')
            .replace('Ü', 'ue')
            .replace('ß', 'sz')
    }

    /**
     * Getter birthName
     * @return {string }
     */
    public get birthName (): string {
        return this._birthName;
    }

    /**
     * Getter street
     * @return {string }
     */
    public get street (): string {
        return this._street;
    }

    /**
     * Getter houseNo
     * @return {number }
     */
    public get houseNo (): string {
        return this._houseNo;
    }

    /**
     * Getter postalCode
     * @return {number }
     */
    public get postalCode (): string {
        return this._postalCode;
    }

    /**
     * Getter city
     * @return {string }
     */
    public get city (): string {
        return this._city;
    }

    /**
     * Getter phoneNumber
     * @return {string }
     */
    public get phoneNumber (): string {
        return this._phoneNumber;
    }

    /**
     * Getter emailAddress
     * @return {string }
     */
    public get emailAddress (): string {
        return this._emailAddress;
    }

    /**
     * Setter birthDay
     * @param {number } value
     */
    public set birthDay (value: number) {
        this._birthDay = value;
    }

    /**
     * Setter birthMonth
     * @param {number } value
     */
    public set birthMonth (value: number) {
        this._birthMonth = value > 0 ? value - 1 : 0;
    }

    /**
     * Setter birthYear
     * @param {number } value
     */
    public set birthYear (value: number) {
        this._birthYear = value;
    }

    /**
     * Setter insuranceSum
     * @param {number } value
     */
    public set insuranceSum (value: number) {
        if (value < 500 || value > 8000) {
            this._insuranceSum = 500;
            // eslint-disable-next-line max-len
            throw new RangeError('Insurance Sum must be greater than 500 and not be greater than 8000');
        }
        this._insuranceSum = value;
    }

    /**
     * Setter insuranceStart
     * @param {Date} value
     */
    public set insuranceStart (value: Date) {
        const lowerLimit = moment().startOf('month');
        const now = moment(value);
        const upperLimit = moment().startOf('month').add(2, 'M');

        if (now.isBefore(lowerLimit) || now.isAfter(upperLimit)) {
            throw new RangeError('Insurance start cannot be before the current month')
        }
        this._insuranceStart = value;
    }

    /**
     * Setter chosenTariff
     * @param {InsuranceTariff } value
     */
    public set chosenTariff (value: InsuranceTariff | undefined) {
        this._chosenTariff = value;
    }

    /**
     * Setter gender
     * @param {string } value
     */
    public set salutation (value: string) {
        this._salutation = value;
    }

    /**
     * Setter title
     * @param {string } value
     */
    public set title (value: string) {
        this._title = value;
    }

    /**
     * Setter firstName
     * @param {string } value
     */
    public set firstName (value: string) {
        this._firstName = value;
    }

    /**
     * Setter lastName
     * @param {string } value
     */
    public set lastName (value: string) {
        this._lastName = value;
    }

    /**
     * Setter birthName
     * @param {string } value
     */
    public set birthName (value: string) {
        this._birthName = value;
    }

    /**
     * Setter street
     * @param {string } value
     */
    public set street (value: string) {
        this._street = value;
    }

    /**
     * Setter houseNo
     * @param {string} value
     */
    public set houseNo (value: string) {
        this._houseNo = value;
    }

    /**
     * Setter postalCode
     * @param {number } value
     */
    public set postalCode (value: string) {
        this._postalCode = value;
    }

    /**
     * Setter city
     * @param {string } value
     */
    public set city (value: string) {
        this._city = value;
    }

    /**
     * Setter phoneNumber
     * @param {string } value
     */
    public set phoneNumber (value: string) {
        this._phoneNumber = value;
    }

    /**
     * Setter emailAddress
     * @param {string } value
     */
    public set emailAddress (value: string) {
        this._emailAddress = value;
    }

    public get paymentInterval (): string {
        return this._paymentInterval;
    }

    public set paymentInterval (value: string) {
        this._paymentInterval = value;
    }

    public get relationshipStatus (): string {
        return this._relationshipStatus;
    }

    public set relationshipStatus (value: string) {
        this._relationshipStatus = value;
    }

    public get bankAccount (): BankAccount {
        return this._bankAccount;
    }

    public set bankAccount (value: BankAccount) {
        this._bankAccount = value;
    }

    public get healthy (): boolean | null {
        return this._healthy;
    }

    public set healthy (value: boolean | null) {
        this._healthy = value;
    }

    public get healthInfo (): string {
        return this._healthInfo;
    }

    public set healthInfo (value: string) {
        this._healthInfo = value;
    }

    public get healthQueryOption (): string {
        return this._healthQueryOption;
    }

    public set healthQueryOption (value: string) {
        this._healthQueryOption = value;
    }

    get deathQueryOption (): string {
        return this._deathQueryOption;
    }

    set deathQueryOption (value: string) {
        this._deathQueryOption = value;
    }

    /**
     * Creates a new instance of the Applicant class
     * @param defaultTariff Optional default tariff
     */
    public constructor (defaultTariff?: InsuranceTariff) {
        this._insuranceStart = TariffUtil.GetEarliestInsuranceStart();
        this._chosenTariff = defaultTariff;
        this._bankAccount = {
            bank: '',
            iban: '',
            bic: '',
            city: '',
            postalCode: '',
            houseNumber: '',
            street: '',
            firstName: '',
            lastName: ''
        } as BankAccount;
    }
}
