





































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Actions } from '@/store';
import Collapse from './Collapse.vue';
import Child from '../models/Child';
import { InputFacade } from 'vue-input-facade';
import moment from 'moment';
import DateUtil from '@/utils/DateUtil';
import RangeInput from '@/components/RangeInput.vue';
import SelectedTariffCard from '@/components/SelectedTariffCard.vue';

@Component({
    components: {
        SelectedTariffCard,
        Collapse,
        RangeInput,
        InputFacade
    }
})
export default class WizardPageChildren extends Vue {
    private lowestInsuranceSum = 500;
    private insuranceSumStep = 500;

    @Prop({ default: '' }) wizardStepId!: string;

    private childrenTempBirthdates: Array<string> = [];
    private childValidation: Map<string, {isInvalid: boolean, message: string}> =
        new Map<string, {isInvalid: boolean; message: string}>();

    /**
     * Return the centrally defined date placeholder
     */
    get datePlaceholder (): string {
        return DateUtil.datePlaceholder;
    }

    /**
     * Returns the highest possible insurance sum for the children
     */
    get highestInsuranceSum (): number {
        let highestInsuranceSum = this.$store.state.applicantData.insuranceSum;
        if (this.$store.state.applicantData.insuranceSum >= 5000) {
            highestInsuranceSum = 5000;
        }

        return highestInsuranceSum;
    };

    /**
     * returns the current chosen tariff identifier
     */
    get chosenTariff (): string {
        return this.$store.state.applicant.chosenTariff.name;
    }

    /**
     * returns the currently set children
     */
    get children (): Array<Child> {
        const children = this.$store.state.children;
        children.forEach((child: Child, index: number) => {
            if (!this.childrenTempBirthdates[index]) {
                this.childrenTempBirthdates[index] = child.birthdate
                    ? moment(child.birthdate).format('DDMMYYYY')
                    : '';
            }
            if (!child.birthdate && !this.childrenTempBirthdates[index]) {
                this.childValidation.set(`child-birthdate-${index}`, {
                    isInvalid: true,
                    message: 'Bitte tragen Sie ein Geburtsdatum ein!'
                });
            }
        });
        return children;
    }

    /**
     * sets an updated version of the children array
     */
    set children (value: Array<Child>) {
        this.$store.dispatch(Actions.UpdateChildren, value);
        this.determineStepValidity();
    }

    get oldestAllowedChildAge (): string {
        return moment().subtract(18, 'years').format('YYYY-MM-DD');
    }

    get earliestAllowedChildAge (): string {
        return moment().format('YYYY-MM-DD');
    }

    /**
     * dispatches a new action to update the birthdate on the child instance
     * associated with the given id.
     */
    onChildBirthDateSelected (index: number, value: string) {
        // check if we have a complete date
        if (value && value.length >= 6) {
            // moment handles conversion; inputting 020510 as a shortcut also works.
            const birthDate = moment(value, 'DDMMYYYY');

            // check if it is a valid date and the 'being a child' constraints check out
            if (birthDate.isValid() && !this.isNotChild(birthDate.toDate())) {
                // if all is well update the birthdate in the store.
                this.$store.dispatch(Actions.UpdateChildBirthday, { index, birthDate: birthDate.toDate() });
            }
        }

        this.determineStepValidity();
    }

    /**
     * Dispatches an action to add a new empty child entry.
     */
    onAddChild (e: Event) {
        e.preventDefault();
        this.$store.dispatch(Actions.AddNewChild)
            .then(() => {
                this.determineStepValidity();
            });
    }

    /**
     * Dispatches an action to remove the child with the specified index
     * Called when the user clicks the remove button on a child
     */
    onRemoveChild (index: number, e: Event) {
        e.preventDefault();
        this.$store.dispatch(Actions.RemoveChild, index).then(() => this.determineStepValidity());
    }

    /**
     * Called when the user changes the insurance sum for a child.
     * Updates the insurance sum in the store
     */
    onChildInsuranceSumChanged (index: number, newInsuranceSum: number) {
        this.determineStepValidity();
        this.$store.dispatch(Actions.UpdateChildInsuranceSum, { index, insuranceSum: newInsuranceSum });
    }

    public created () {
        this.determineStepValidity();
    }

    /**
     * checks if all child birth dates are in the valid range of 0 to 18 years old.
     */
    checkValidBirthDates (): boolean {
        // all dates are assumed to be correct at the beginning
        let childrenBirthDatesValid = true;

        this.childrenTempBirthdates.forEach((birthdate, index) => {
            // then we iterate over each child to check if its
            // birthdate is valid.
            if (!birthdate || birthdate.length < 6) {
                // If not, we set the variable defined outside the loop to false
                // so that the process will fail no matter what follows.
                childrenBirthDatesValid = false;

                // date is invalid => set error message and return
                this.setValidation(`child-birthdate-${index}`,
                    true,
                    'Eingabe muss ein gültiges Datum sein!'
                );
                return;
            }

            // if the birthdate is not null
            const birthDate = moment(birthdate, 'DDMMYYYY');
            // eslint-disable-next-line
            console.debug('Validation birthdate', birthDate);

            if (!birthDate.isValid()) {
                // date is invalid => set error message and return
                this.setValidation(`child-birthdate-${index}`,
                    true,
                    'Eingabe muss ein gültiges Datum sein!'
                );

                childrenBirthDatesValid = false;
                return;
            }
            // eslint-disable-next-line
            console.debug('Child bd' + index + ' is valid.');

            if (this.isNotChild(birthDate.toDate())) {
                // date is not between 0 and 17 => invalid!
                childrenBirthDatesValid = false;
                // eslint-disable-next-line
                console.debug('Child ' + index + ' is not a child!');

                this.setValidation(`child-birthdate-${index}`,
                    true,
                    'Die maximale Altersgrenze für Kinder beträgt 17 Jahre. ' +
                    'Vor der Geburt können keine Verträge abgeschlossen werden.'
                );
                return;
            }
            // eslint-disable-next-line
            console.log('Child bd ' + index + ' was checked successfully.');
            // if we got to here, all checks were passed - we assume it is
            // valid and clear the error message
            this.setValidation(`child-birthdate-${index}`, false);
        });
        // update the step validity depending on the result
        this.$store.dispatch(Actions.UpdateStepValidation,
            { stepId: this.wizardStepId, isValid: childrenBirthDatesValid });
        // return the test result
        return childrenBirthDatesValid;
    }

    /**
     * Checks if the birthdate is describing a child (> now and < 18 years old)
     */
    isNotChild (date: Date): boolean {
        return moment(date).isBefore(moment().subtract(18, 'years')) || moment(date).isAfter(moment());
    }

    changeChildFirstName (index: number, event: Event): void {
        this.children[index].firstName = (event.target as HTMLInputElement).value;
        this.determineStepValidity();
        this.$store.dispatch(Actions.UpdateChildren, this.children);
    }

    changeChildLastName (index: number, event: Event): void {
        this.children[index].lastName = (event.target as HTMLInputElement).value;
        this.determineStepValidity();
        this.$store.dispatch(Actions.UpdateChildren, this.children);
    }

    changeChildGender (index: number, event: Event): void {
        this.children[index].gender = (event.target as HTMLInputElement).value;
        this.determineStepValidity();
        this.$store.dispatch(Actions.UpdateChildren, this.children);
    }

    /**
     * Check if all children have insurance sums set
     */
    private childInsuranceSumsSet () {
        let isValid = true;
        this.children.forEach((child, index) => {
            if (child.insuranceSum === undefined) {
                isValid = false;
            }
        });
        this.$store.dispatch(Actions.UpdateStepValidation, { stepId: this.wizardStepId, isValid: isValid });
    }

    /**
     * Performs checks to see if the current step is valid
     */
    private determineStepValidity () {
        if (!this.checkValidBirthDates()) {
            return;
        }

        if (!this.$store.state.exhibitionMode) {
            if (!this.checkRequiredFields()) {
                return;
            }
        }
        this.childInsuranceSumsSet();
    }

    private setValidation (fieldName: string, isInvalid: boolean, message: string = ''): void {
        this.childValidation.set(fieldName, {
            isInvalid,
            message
        });
    }

    private getValidation (fieldName: string): { isInvalid: boolean, message: string } {
        if (!this.childValidation.has(fieldName)) {
            const newValidationObj = { isInvalid: false, message: '' };
            this.childValidation.set(fieldName, newValidationObj);
            return newValidationObj;
        }
        return this.childValidation.get(fieldName) as { isInvalid: boolean, message: string };
    }

    private checkRequiredFields (): boolean {
        let isValid = true;
        for (let childIdx = 0; childIdx < this.children.length; childIdx++) {
            this.setValidation(`child-firstname-${childIdx}`, false);
            this.setValidation(`child-lastname-${childIdx}`, false);
            this.setValidation(`child-gender-${childIdx}`, false);

            if (!this.children[childIdx].firstName || this.children[childIdx].firstName.length < 2) {
                this.setValidation(`child-firstname-${childIdx}`,
                    true, 'Bitte geben Sie den Vornamen des Kindes an.');
                isValid = false;
            }
            if (!this.children[childIdx].lastName || this.children[childIdx].lastName.length < 2) {
                this.setValidation(`child-lastname-${childIdx}`,
                    true, 'Bitte geben Sie den Nachnamen des Kindes an.');
                isValid = false;
            }
            if (!this.children[childIdx].gender) {
                this.setValidation(`child-gender-${childIdx}`,
                    true, 'Bitte geben Sie das Geschlecht des Kindes an.');
                isValid = false;
            }
        }
        // update the step validity depending on the result
        this.$store.dispatch(Actions.UpdateStepValidation,
            { stepId: this.wizardStepId, isValid: isValid });
        return isValid;
    }
}
