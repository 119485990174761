import Applicant from '@/models/Applicant';
import Child from '@/models/Child';
import InsuranceTariff from '@/models/InsuranceTariff';
import StoreState from '@/models/StoreState';
import WizardStep from '@/models/WizardStep';
import TariffUtil from '@/utils/TariffUtil';
import Vue from 'vue';
import Vuex from 'vuex';
import TariffDetails from '@/models/TariffDetails';
import { BankAccount } from '@/models/BankAccount';
import { RequestUtil } from '@/utils/RequestUtil';
import moment from 'moment/moment';
import NumberUtil from '@/utils/NumberUtil';
import Swal from 'sweetalert2';

Vue.use(Vuex);

export default function createStore () {
    const mountElement = document.getElementById('insurance-calculator') as HTMLElement;
    const exhibitionMode = !!mountElement.dataset['exhibitionMode'] &&
        mountElement.dataset['exhibitionMode'] === 'true';
    const brokerId: string = mountElement.hasAttribute('broker-id')
        ? mountElement.getAttribute('broker-id') as string
        : '10';
    const brokerMail: string = mountElement.hasAttribute('broker-mail')
        ? mountElement.getAttribute('broker-mail') as string
        : '';
    const financeAdsId = mountElement.hasAttribute('financeads')
        ? mountElement.getAttribute('financeads')
        : null;
    const poolBrokerId = mountElement.hasAttribute('pool-broker-id')
        ? mountElement.getAttribute('pool-broker-id')
        : null;

    return new Vuex.Store({
        /**
         * This is the data the application works with.
         * It is only changed by mutations, which in turn,
         * are only invoked by actions.
         *
         * The values set here, are the default values.
         *
         * action => mutation => state change
         */
        state: new StoreState(exhibitionMode, brokerId, brokerMail, financeAdsId, poolBrokerId),
        /**
         * Mutations are the only functions allowed to change the state.
         * This helps to make sure that only one write access to the state exists
         * and not everything can write to it.
         * Mutations are usually invoked by actions.
         */
        mutations: {
            enableLoadingStatus (state, text: string = '') {
                state.isLoading = true;
                state.loadingText = text;
            },
            disableLoadingStatus (state) {
                state.isLoading = false;
            },
            updateTariffs (state, tariffs: Array<InsuranceTariff>) {
                state.tariffs = new Map(
                    tariffs.map(tariff => [tariff.name, tariff])
                );
            },
            updateApplicantData (state, newInfo) {
                // store the new info
                state.applicantData = newInfo;
                try {
                    // update the monthly sum
                    state.calculatedSum = TariffUtil.CalculateMonthlySum(state.applicantData, state.children);
                } catch (error) {
                    // eslint-disable-next-line
                    console.warn('[UpdateApplicantData] Insurance sum calculation failed! ' + error)
                }
            },
            updateApplicantBankAccount (state, bankAccount) {
                // store the new info
                state.applicantData.bankAccount = bankAccount;
            },
            selectDefaultTariff (state) {
                // state.applicantData.chosenTariff = state.tariffs.get('C0385');
                // const c03 = state.tariffDetails.find((elem) => elem.identifier === 'C03') as TariffDetails;
                // c03.isPreSelected = true;
                // c03.optionsExpanded = true;
            },
            updateCalculatedSum (state, newSum) {
                state.calculatedSum = newSum;
            },
            updateCurrentStep (state, newStepId: string) {
                state.currentStep = newStepId;
                const calculatorElem = document.querySelectorAll('#insurance-calculator').item(0);
                const absolutePositionInDoc = window.pageYOffset + calculatorElem.getBoundingClientRect().top - 100;

                scrollTo({ left: 0, top: absolutePositionInDoc, behavior: 'smooth' });
            },
            updateStepState (state, newStepState: WizardStep) {
                const targetIndex = state.steps.findIndex(step => step.id === newStepState.id);
                state.steps = state.steps.map((s, i) => {
                    if (i === targetIndex) {
                        return newStepState;
                    }
                    return s;
                });
            },
            updateChildren (state, newChildren: Array<Child>) {
                Vue.set(state, 'children', newChildren);
            },
            setSendFormsViaMail (state, active: boolean) {
                state.sendFormsViaMail = active;
            },
            updateComments (state, comment: string) {
                state.comment = comment;
            }
        },
        /**
         * Actions are invoked by your code and handle any http requests or
         * other transformations of the data before calling a mutation to
         * change the state
         */
        actions: {
            /**
             * Adds a new, empty child object
             * @param param0 The vueex instance
             */
            addNewChild ({ commit, state }) {
                const children = state.children;
                children.push({
                    birthdate: null,
                    insuranceSum: 500,
                    firstName: '',
                    lastName: '',
                    gender: ''
                });
                commit('updateChildren', children);
                this.dispatch('updateCalculatedSum');
            },
            /**
             * Activate the loading state of the application with an optional
             * status text.
             * @param param0 The vueex instance
             * @param message The message to show below the loading indicator.
             */
            enableLoadingStatus ({ commit }, message: string) {
                commit('enableLoadingStatus', message);
            },
            /**
             * Disables the loading state of the application
             * @param param0 the vueex instance.
             */
            disableLoadingStatus ({ commit }) {
                commit('disableLoadingStatus');
            },
            /**
             * Fetches the contract/tariff definitions from the online
             * repository.
             * @param param0 The vueex instance
             */
            async fetchTariffs ({ commit }) {
                let tariffArray = [];
                try {
                    const response = await fetch('https://cdn.bestatterwebtool.de/gedenkportal/gpNavProxy.php' +
                        '?url=versicherung.ge-be-in.de/files/json/contract-definitions.json');
                    tariffArray = response.ok ? await response.json() : [];

                    // convert the simple json objects to a structured
                    // array of InsuranceTariff objects and call the updateTariffs
                    // mutation to commit them to the store.
                    commit(
                        'updateTariffs',
                        TariffUtil.TariffArrayFromJson(tariffArray)
                    );
                } catch (err) {
                    // eslint-disable-next-line
                    console.warn(err);
                } finally {
                    commit('selectDefaultTariff');
                }
            },
            fetchBankInfoFromIBAN ({ commit, state }, iban) {
                let xhr = new XMLHttpRequest();

                xhr.addEventListener('load', ev => {
                    const ibanInfo = JSON.parse(xhr.responseText);
                    if (ibanInfo['valid'] === false) {
                        state.isLoading = false;
                        return;
                    }

                    const bankAccount: BankAccount = state.applicantData.bankAccount;
                    bankAccount.bic = ibanInfo['bankData']['bic'];
                    bankAccount.bank = ibanInfo['bankData']['name'];

                    commit(
                        'updateApplicantBankAccount',
                        bankAccount
                    );
                    commit('disableLoadingStatus');
                });
                xhr.addEventListener('error', ev => {
                    Swal.fire({
                        title: 'Fehler',
                        text: 'BIC und Bankname konnten nicht automatisch ergänzt werden. ' +
                            'Bitte tragen Sie diese manuell ein!',
                        backdrop: true,
                        buttonsStyling: true,
                        confirmButtonColor: 'rgba(26, 74, 126, 0.8)'
                    });
                    commit('disableLoadingStatus');
                });
                xhr.open(
                    'GET',
                    // eslint-disable-next-line max-len
                    `https://openiban.com/validate/${iban}?getBIC=true`
                );
                commit('enableLoadingStatus', 'BIC und Bankname werden ermittelt...');
                xhr.send();
            },
            /**
             * Removes a child from the store.
             * @param param0 The vuex instance
             * @param index The index at which to remove the child
             */
            removeChild ({ commit, state }, index) {
                let children = state.children;
                children.splice(index, 1);
                commit('updateChildren', children);
                this.dispatch('updateCalculatedSum');
            },
            setSendFormsViaMail ({ commit }, enabled: boolean) {
                commit('setSendFormsViaMail', enabled);
            },
            /**
             * Updates the applicant info to the specified data.
             * @param param0 The vueex instance
             * @param applicantData Data to update the applicant info with
             */
            updateApplicant ({ commit }, applicantData: Applicant) {
                commit('updateApplicantData', applicantData);
            },
            /**
             * Updates the insurance start date with the supplied date.
             * @param param0 The vueex parameters
             * @param newStartDate The updated insurance start date.
             */
            updateInsuranceStartDate ({ commit, state }, newStartDate: Date) {
                const applicant = state.applicantData;
                applicant.insuranceStart = newStartDate;

                commit('updateApplicantData', applicant)
            },
            /**
             * Updates the applicants birth date in the store.
             * @param param0 The vueex parameters
             * @param newBirthDateParts Year, Month and Day of the updated birth date (in that order).
             */
            updateApplicantBirthDate ({ commit, state }, newBirthDateParts: Array<number>) {
                const applicant = state.applicantData;
                if (newBirthDateParts !== null && newBirthDateParts.length > 0) {
                    applicant.birthYear = newBirthDateParts[0];
                    applicant.birthMonth = newBirthDateParts[1];
                    applicant.birthDay = newBirthDateParts[2];
                } else {
                    applicant.birthDay = 1;
                    applicant.birthMonth = 0;
                    applicant.birthYear = 0;
                }

                commit('updateApplicantData', applicant);
            },
            /**
             * Updates the applicants insurance sum
             * @param param0 The vueex parameters
             * @param newInsuranceSum The updated applicants' insurance sum
             */
            updateApplicantInsuranceSum ({ commit, state }, newInsuranceSum: number) {
                const applicant = state.applicantData;

                applicant.insuranceSum = newInsuranceSum;

                commit('updateApplicantData', applicant);
            },
            updateApplicantChosenTariff ({ commit, state }, newTariff: InsuranceTariff) {
                const applicant = state.applicantData;

                applicant.chosenTariff = newTariff;

                commit('updateApplicantData', applicant);
            },
            updateCalculatedSum ({ commit, state }) {
                const newSum = TariffUtil.CalculateMonthlySum(state.applicantData, state.children);
                commit('updateCalculatedSum', newSum);
            },
            updateCurrentStep ({ commit }, stepId: string) {
                commit('updateCurrentStep', stepId);
            },
            updateStepValidation ({ commit, state }, payload: { stepId: string, isValid: boolean }) {
                const step = state.steps.find((step: WizardStep) => step.id === payload.stepId);
                if (!step) {
                    return;
                }
                step.isValid = payload.isValid;
                commit('updateStepState', step);
            },
            updateChildren ({ commit }, payload: Array<Child>) {
                commit('updateChildren', payload);
                this.dispatch('updateCalculatedSum');
            },
            updateChildBirthday ({ commit, state }, payload: { index: number, birthDate: Date }) {
                commit('updateChildren', state.children.map((c, i) => {
                    if (i === payload.index) {
                        c.birthdate = payload.birthDate;
                    }
                    return c;
                }));
                this.dispatch('updateCalculatedSum');
            },
            updateChildInsuranceSum ({ commit, state }, payload: { index: number, insuranceSum: number }) {
                commit('updateChildren', state.children.map((e, i) => {
                    if (i === payload.index) {
                        e.insuranceSum = payload.insuranceSum;
                    }
                    return e;
                }));
                this.dispatch('updateCalculatedSum');
            },
            updateApplicantRelationshipStatus ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.relationshipStatus = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantPaymentInterval ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.paymentInterval = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantEmail ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.emailAddress = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantPhoneNumber ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.phoneNumber = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantBirthName ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.birthName = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantCity ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.city = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantPostalCode ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.postalCode = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantLastName ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.lastName = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantFirstName ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.firstName = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantHouseNumber ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.houseNo = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantStreet ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.street = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantTitle ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.title = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantSalutation ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.salutation = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantBankAccount ({ commit, state }, payload: BankAccount) {
                const applicant = state.applicantData;

                applicant.bankAccount = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantUseBankAccount ({ commit, state }, payload: boolean) {
                const applicant = state.applicantData;

                applicant.useBankAccount = payload;

                commit('updateApplicantData', applicant);
            },
            updateComment ({ commit }, payload: string) {
                commit('updateComments', payload);
            },
            updateApplicantHealthStatus ({ commit, state }, payload: boolean) {
                const applicant = state.applicantData;

                applicant.healthy = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantHealthInfo ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.healthInfo = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantHealthQueryOption ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.healthQueryOption = payload;

                commit('updateApplicantData', applicant);
            },
            updateApplicantDeathQueryOption  ({ commit, state }, payload: string) {
                const applicant = state.applicantData;

                applicant.deathQueryOption = payload;

                commit('updateApplicantData', applicant);
            },
            updateAdConsent ({ commit, state }, payload: { offline: boolean, online: boolean }) {
                const applicant = state.applicantData;

                applicant.adConsentOffline = payload.offline;
                applicant.adConsentOnline = payload.online;

                commit('updateApplicantData', applicant);
            },
            updatePrivacyAccepted ({ commit, state }, payload: { accepted: boolean }) {
                const applicant = state.applicantData;
                applicant.privacyAccepted = payload.accepted;

                commit('updateApplicantData', applicant);
            },
            updateInfosRead ({ commit, state }, payload: { accepted: boolean }) {
                const applicant = state.applicantData;
                applicant.infosRead = payload.accepted;

                commit('updateApplicantData', applicant);
            },
            updateToldTheTruth ({ commit, state }, payload: { accepted: boolean }) {
                const applicant = state.applicantData;
                applicant.toldTheTruth = payload.accepted;

                commit('updateApplicantData', applicant);
            },
            updateConfirmDocumentsAccess ({ commit, state }, payload: { accepted: boolean }) {
                const applicant = state.applicantData;
                applicant.confirmDocumentsAccess = payload.accepted;

                commit('updateApplicantData', applicant);
            },

            async submitApplication ({ commit, state }) {
                let tokenFieldName: string, requestToken: string, tokenId: string;
                tokenFieldName = '';
                requestToken = '';
                tokenId = '';

                // fetch request token and form name
                try {
                    [tokenFieldName, tokenId, requestToken] = await RequestUtil.getFormToken('/api/insurance-calculator/token');
                } catch (error) {
                    Swal.fire({
                        title: 'Fehler',
                        text: 'Der Tokenabruf ist fehlgeschlagen ' +
                            'Bitte überprüfen Sie ihre Internetverbindung und versuchen Sie es dann erneut.',
                        backdrop: true,
                        buttonsStyling: true,
                        confirmButtonColor: 'rgba(26, 74, 126, 0.8)'
                    });
                }

                // fill formdata array with the values
                let formData: FormData = new FormData();
                formData.set('REQUEST_TOKEN', requestToken);
                formData.set('TOKEN_ID', tokenId);
                formData.set('financeads', '');
                formData.set('birthday', state.applicantData.birthDay.toString());
                formData.set('birthmonth', state.applicantData.birthMonth.toString());
                formData.set('birthyear', state.applicantData.birthYear.toString());
                formData.set('insurancesum', NumberUtil.toLocalizedNumberString(state.applicantData.insuranceSum));
                formData.set('startmonth', (state.applicantData.insuranceStart.getMonth() + 1).toString());
                formData.set('tariff', state.applicantData.chosenTariff
                    ? state.applicantData.chosenTariff.name.substr(0, 3)
                    : '');
                formData.set('anrede', state.applicantData.salutation);
                formData.set('title', state.applicantData.title);
                formData.set('name', state.applicantData.firstName);
                formData.set('surname', state.applicantData.lastName);
                formData.set('birthname', state.applicantData.birthName);
                formData.set('street', state.applicantData.street);
                formData.set('housenumber', state.applicantData.houseNo);
                formData.set('postalcode', state.applicantData.postalCode);
                formData.set('city', state.applicantData.city);
                formData.set('telephone', state.applicantData.phoneNumber);
                formData.set('email', state.applicantData.emailAddress);
                formData.set('personalstatus', state.applicantData.relationshipStatus);
                formData.set('payement', state.applicantData.paymentInterval);
                formData.set('bankaccountname', state.applicantData.bankAccount.firstName);
                formData.set('bankaccountsurname', state.applicantData.bankAccount.lastName);
                formData.set('bankaccountstreet', state.applicantData.bankAccount.street);
                formData.set('bankaccountnumber', state.applicantData.bankAccount.houseNumber);
                formData.set('bankaccountpostalcode', state.applicantData.bankAccount.postalCode);
                formData.set('bankaccountcity', state.applicantData.bankAccount.city);
                formData.set('iban', state.applicantData.bankAccount.iban);
                formData.set('bic', state.applicantData.bankAccount.bic);
                formData.set('bankname', state.applicantData.bankAccount.bank);
                formData.set('message', state.comment);
                formData.set('postzusendung', state.sendFormsViaMail ? 'Post' : '');
                formData.set('datenschutz', 'akzeptiert');
                formData.set('versandart', 'Online-PDF');
                formData.set('tariffdetail', state.applicantData.chosenTariff ? state.applicantData.chosenTariff.name.substr(0, 3) : '');
                formData.set('tariffC03Detail', state.applicantData.chosenTariff &&
                    state.applicantData.chosenTariff.name.startsWith('C03')
                    ? state.applicantData.chosenTariff.name.substr(3, 2)
                    : ''
                );

                const childWithoutC03 = (
                    state.applicantData.chosenTariff &&
                    !state.applicantData.chosenTariff.name.startsWith('C03') &&
                    !!state.children.length) ? 'true' : 'false';
                formData.set('childWithoutC03', childWithoutC03);

                const applicantTariffFee = TariffUtil.CalculateMonthlySum(state.applicantData);
                formData.set('payvaluedeformat', NumberUtil.toLocalizedNumberString(applicantTariffFee));
                formData.set('onetimepayvalue', NumberUtil.toLocalizedNumberString(applicantTariffFee));

                formData.set('monatsbeitrag', NumberUtil.toLocalizedNumberString(applicantTariffFee));

                formData.set('yearFeeHiddenField', NumberUtil.toLocalizedNumberString(12 * applicantTariffFee));
                formData.set('tarifbeitrag', NumberUtil.toLocalizedNumberString(applicantTariffFee));
                formData.set('tarifM', TariffUtil.getTariffMValue(state));

                formData.set('tarifmmonthly', TariffUtil.getTariffMMonthlyValue(state)
                    ? TariffUtil.getTariffMMonthlyValue(state)
                    : NumberUtil.toLocalizedNumberString(applicantTariffFee));

                formData.set('tinyupname', state.applicantData.lastNameNoUmlauts);
                formData.set('startdate', moment(state.applicantData.insuranceStart).format('DD.MM.YYYY'));
                formData.set('gender', state.applicantData.salutation === 'Frau' ? 'weiblich' : 'männlich');
                formData.set('vermitler', state.brokerId); // this works, so it is already wrong in the backend
                formData.set('vermittlerMail', state.brokerMail);
                formData.set('financeads', state.financeAdsId ? state.financeAdsId : '');
                formData.set('poolBrokerId', state.poolBrokerId ? state.poolBrokerId : '');
                formData.set('insurancesumHidden',
                    NumberUtil.toLocalizedNumberStringNoFractions(state.applicantData.insuranceSum));

                const birthDay = state.applicantData.birthDay.toString().padStart(2, '0');
                const birthMonth = state.applicantData.birthMonth.toString().padStart(2, '0');
                const birthYear = state.applicantData.birthYear;
                const fullBirthday = `${birthDay}.${birthMonth}.${birthYear}`;
                formData.set('birthdate', fullBirthday);

                formData.set('endofpaymentdate', moment(TariffUtil.GetEndOfPaymentDate(state)).format('DD.MM.YYYY'));
                formData.set('waitingtime', moment(state.applicantData.insuranceStart).add(3, 'years').format('DD.MM.YYYY'));
                formData.set('datenow', moment(Date.now()).format('DD.MM.YYYY'));

                // health data
                formData.set('healthy', state.applicantData.healthy !== null
                    ? state.applicantData.healthy.toString()
                    : '');
                formData.set('healthInfo', state.applicantData.healthInfo);
                formData.set('healthQueryOption', state.applicantData.healthQueryOption);
                formData.set('deathQueryOption', state.applicantData.deathQueryOption);
                formData.set('adConsentOffline', state.applicantData.adConsentOffline.toString());
                formData.set('adConsentOnline', state.applicantData.adConsentOnline.toString());

                for (let childIdx = 0; childIdx < 2; childIdx++) {
                    const child = this.state.children[childIdx];

                    formData.set('child' + (childIdx + 1) + 'name', child ? child.firstName : '');
                    formData.set('child' + (childIdx + 1) + 'surname', child ? child.lastName : '');
                    formData.set('child' + (childIdx + 1) + 'birthdate', child ? (child.birthdate
                        ? child.birthdate.getDay().toString() : '') : '');
                    formData.set('child' + (childIdx + 1) + 'birthmonth', child ? (child.birthdate
                        ? (child.birthdate.getMonth() + 1).toString() : '') : '');
                    formData.set('child' + (childIdx + 1) + 'birthyear', child ? (child.birthdate
                        ? child.birthdate.getDay().toString() : '') : '');
                    formData.set('child' + (childIdx + 1) + 'gender', child ? child.gender : '');
                    formData.set('child' + (childIdx + 1) + 'insurancesum',
                        child ? NumberUtil.toLocalizedNumberString(child.insuranceSum) : '');
                    formData.set('child' + (childIdx + 1) + 'monthly',
                        child ? NumberUtil.toLocalizedNumberString(TariffUtil.CalculateSumForChild(child)) : '');
                    formData.set('child' + (childIdx + 1) + 'yearly',
                        child ? TariffUtil.CalculateYearlySumForChild(child) : '');
                    formData.set('child' + (childIdx + 1) + 'insurancesumHidden',
                        child ? NumberUtil.toLocalizedNumberStringNoFractions(child.insuranceSum)
                            : '');
                    formData.set('birthdatechild' + (childIdx + 1), child ? moment(child.birthdate)
                        .format('DD.MM.YYYY') : '');
                }

                // post to /beitragsrechner-internes-formular.html
                RequestUtil.postFormData(formData, '/api/insurance-calculator/application')
                    .then(r => {
                        // eslint-disable-next-line
                        console.info('Insurance application submitted');
                        Swal.fire({
                            title: 'Antrag übermittelt',
                            html: '<p>Es freut uns, dass Sie mit der GE·BE·IN Sterbegeldversicherung frühzeitig vorsorgen ' +
                                'und Verantwortung übernehmen möchten.</p><p>Sie erhalten nun eine E-Mail mit Ihrem Antrag ' +
                                'und allen relevanten Informationen.<br> ' +
                                'Bitte senden Sie uns den Antrag und das Beratungsprotokoll unterschrieben zurück.</p>',
                            backdrop: true,
                            buttonsStyling: true,
                            confirmButtonColor: 'rgba(26, 74, 126, 0.8)'
                        }).then(() => {
                            document.location.reload();
                        });

                        if (state.financeAdsId) {
                            // https://www.financeads.net/tl.php?p=620&oid={{date::Ymd_His}}_{{post::tinyupname}}
                            // &ocategory=sterbegeld&ovalue={{post::insurancesum}}&s_id={{post::financeads}}
                            const tinyupname = state.applicantData.lastNameNoUmlauts;
                            const financeAdsDate = moment().format('YYYYMMDD_HHmmss');
                            const financeAdsId = state.financeAdsId;
                            const insuranceSum = state.applicantData.insuranceSum;

                            fetch(`https://www.financeads.net/tl.php?p=620&oid=${financeAdsDate}_` +
                            `${tinyupname}&ocategory=sterbegeld&ovalue=${insuranceSum}&s_id=${financeAdsId}`);
                        }
                    })
                    .catch(e => {
                        // eslint-disable-next-line
                        console.error('Insurance application could not be submitted: ', e);
                        Swal.fire({
                            title: 'Fehler',
                            text: 'Die Antragsdaten konnten nicht übermittelt werden,' +
                                ' bitte überprüfen Sie ihre Internetverbindung und versuchen Sie es dann erneut!',
                            backdrop: true,
                            buttonsStyling: true,
                            confirmButtonColor: 'rgba(26, 74, 126, 0.8)'
                        });
                    })
                    .finally(() => {
                        commit('disableLoadingStatus');
                    });
            }
        },
        modules: {}
    });
}

export enum Actions {
    AddNewChild='addNewChild',
    DisableLoadingStatus='disableLoadingStatus',
    EnableLoadingStatus='enableLoadingStatus',
    FetchTariffs='fetchTariffs',
    FetchBankInfoFromIBAN='fetchBankInfoFromIBAN',
    RemoveChild='removeChild',
    SetSendFormsViaMail='setSendFormsViaMail',
    UpdateInsuranceStartDate='updateInsuranceStartDate',
    UpdateApplicantBirthDate='updateApplicantBirthDate',
    UpdateApplicantInsuranceSum='updateApplicantInsuranceSum',
    UpdateApplicantChosenTariff='updateApplicantChosenTariff',
    UpdateApplicantRelationshipStatus='updateApplicantRelationshipStatus',
    UpdateApplicantPaymentInterval='updateApplicantPaymentInterval',
    UpdateApplicantEmail='updateApplicantEmail',
    UpdateApplicantPhoneNumber='updateApplicantPhoneNumber',
    UpdateApplicantBirthName='updateApplicantBirthName',
    UpdateApplicantCity='updateApplicantCity',
    UpdateApplicantPostalCode='updateApplicantPostalCode',
    UpdateApplicantLastName='updateApplicantLastName',
    UpdateApplicantFirstName='updateApplicantFirstName',
    UpdateApplicantHouseNumber='updateApplicantHouseNumber',
    UpdateApplicantStreet='updateApplicantStreet',
    UpdateApplicantTitle='updateApplicantTitle',
    UpdateApplicantSalutation='updateApplicantSalutation',
    UpdateCurrentStep='updateCurrentStep',
    UpdateStepValidation='updateStepValidation',
    UpdateChildren='updateChildren',
    UpdateChildBirthday='updateChildBirthday',
    UpdateChildInsuranceSum='updateChildInsuranceSum',
    UpdateApplicantBankAccount='updateApplicantBankAccount',
    UpdateApplicantUseBankAccount='updateApplicantUseBankAccount',
    UpdateComment='updateComment',
    SubmitApplication='submitApplication',
    ExtractDataFromUrl='extractDataFromUrl',
    UpdateApplicantHealthStatus='updateApplicantHealthStatus',
    UpdateApplicantHealthInfo='updateApplicantHealthInfo',
    UpdateApplicantHealthQueryOption='updateApplicantHealthQueryOption',
    UpdateApplicantDeathQueryOption='updateApplicantDeathQueryOption',
    UpdateAdConsent='updateAdConsent',
    UpdatePrivacyAccepted='updatePrivacyAccepted',
    UpdateInfosRead='updateInfosRead',
    UpdateToldTheTruth='updateToldTheTruth',
    UpdateConfirmDocumentsAccess='updateConfirmDocumentsAccess',
}
