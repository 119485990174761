import Applicant from '@/models/Applicant';
import Child from '@/models/Child';
import InsuranceTariff from '@/models/InsuranceTariff';
import moment from 'moment';
import TariffDetails from '@/models/TariffDetails';
import TariffOption from '@/models/TariffOption';
import StoreState from '@/models/StoreState';
import NumberUtil from '@/utils/NumberUtil';

export default class TariffUtil {
    /**
     * Calculate the multiplier of the insurance sum
     */
    public static CalculateMultiplier (insuranceSum: number): number {
        return Math.floor(insuranceSum / 500);
    }

    /**
     * Calculate the monthly payment sum for the applicant
     */
    public static CalculateMonthlySum (applicant: Applicant, children: Array<Child> = []): number {
        if (!applicant.chosenTariff) {
            return 0;
        }

        const multiplier = this.CalculateMultiplier(applicant.insuranceSum);
        const baseFee = applicant.chosenTariff.valuesAtAge.get(applicant.age);

        if (!baseFee) {
            return 0; // throw new RangeError('BaseFee for age not found');
        }

        let fees = [];

        const applicantFee = applicant.chosenTariff.name === 'E03'
            ? (+baseFee * multiplier)
            : (+baseFee / 12 * multiplier);
        fees.push(applicantFee);

        if (children === undefined) {
            children = [];
        }
        for (const child of children) {
            fees.push(this.CalculateSumForChild(child));
        }
        return fees.reduce((prev, current) => prev + current);
    }

    static getTariffMValue (state: StoreState) {
        if (!state.applicantData.chosenTariff) {
            return '';
        }
        const tariffMData = state.tariffs.get(state.applicantData.chosenTariff.name + 'M');
        if (!tariffMData) {
            return '';
        }
        let tarifMValue = tariffMData.valuesAtAge.get(state.applicantData.age);
        if (!tarifMValue) {
            return '';
        }

        // Tarifbeitrag (M)
        const multiplier = this.CalculateMultiplier(state.applicantData.insuranceSum);
        tarifMValue = tarifMValue * multiplier;

        return NumberUtil.toLocalizedNumberString(tarifMValue);
    }

    static getTariffMMonthlyValue (state: StoreState) {
        if (!state.applicantData.chosenTariff) {
            return '';
        }
        const tariffMData = state.tariffs.get(state.applicantData.chosenTariff.name + 'M');
        if (!tariffMData) {
            return '';
        }
        let tarifMValue = tariffMData.valuesAtAge.get(state.applicantData.age);
        if (!tarifMValue) {
            return '';
        }

        // Tarifbeitrag (M)
        const multiplier = this.CalculateMultiplier(state.applicantData.insuranceSum);
        tarifMValue = tarifMValue * multiplier;

        return NumberUtil.toLocalizedNumberString(tarifMValue / 12);
    }

    public static CalculateSumForChild (child: Child): number {
        const jearlyFee = +(child.insuranceSum / 500 * 0.48).toFixed(2);
        const monthlyFee = +(jearlyFee / 12).toFixed(2);
        return monthlyFee;
    }

    static CalculateYearlySumForChild (child: Child) {
        return NumberUtil.toLocalizedNumberString(TariffUtil.CalculateSumForChild(child) * 12);
    }

    /**
     * Returns the available tariff start dates based on the current date
     */
    public static GetInsuranceStartDates (): Array<moment.Moment> {
        return [
            moment().startOf('month'),
            moment().startOf('month').add(1, 'M'),
            moment().startOf('month').add(2, 'M')
        ];
    }

    /**
     * Returns the date of the earliest insurance start date
     */
    public static GetEarliestInsuranceStart (): Date {
        let now = moment();
        now.startOf('month');
        return now.toDate();
    }
    /**
     * Converts the given array of objects to an Array of InsuranceTariffs
     * @param jsonArray The Array converted from JSON
     */
    public static TariffArrayFromJson (
        jsonArray: Array<any>
    ): Array<InsuranceTariff> {
        const converted: Array<InsuranceTariff> = [];
        let ageCounter = 18; // Tariffs start for applicants of at least 18 years old.

        for (const elem of jsonArray) {
            // elem = { C0370M: 47.64, C0370: 46.14, C0385M: 27.84, C0385: 26.34, C0485M: 26.88 }

            for (const [tariffName, tariffValue] of Object.entries(elem)) {
                // tariffName = C0370M, tariffValue = 47.64

                // try to find the tarif with this name
                let tariff = converted.find(
                    tariff => tariff.name === tariffName
                );

                // if it does not exist yet, we create the tariff object and push it
                // in the array
                if (!tariff) {
                    tariff = {
                        name: tariffName,
                        valuesAtAge: new Map<number, number>(),
                        isMonthly: tariffName.startsWith('C')
                    };
                    converted.push(tariff);
                }

                // set the age according to the current counter
                tariff.valuesAtAge.set(ageCounter, tariffValue as number);
            }

            // increment the age so we can process the next entry
            ageCounter++;
        }

        return converted;
    }

    public static includesTariffOptionForAge (age: number,
        tariff: TariffDetails,
        tariffOption: TariffOption,
        tariffs: Map<string, InsuranceTariff>
    ): boolean {
        const tariffInstance = tariffs.get(tariff.identifier + tariffOption.identifier);

        if (!tariffInstance) {
            return false;
        }

        return tariffInstance.valuesAtAge.has(age);
    }

    /**
     *  check if the current selected tariff is allowed for the given age
     * if no tariff is found, it is allowed
     * @param tariff: TariffDetails
     * @returns boolean
     */

    public static isCurrentTariffAllowedForAge (applicantData: Applicant|undefined): boolean {
        if (!applicantData) {
            return false;
        }

        const tariff = applicantData.chosenTariff;
        if (!tariff) {
            return false;
        }

        const age = applicantData.age;
        return tariff.valuesAtAge.has(age);
    }

    /**
     * Checks if the given array of InsuranceTariffs contains a tariff
     * with the specified name
     * @param name Name of the InsuranceTariff to look for
     * @param array Array to check
     */
    private static includesTariffWithName (
        name: string,
        array: Array<InsuranceTariff>
    ): boolean {
        return array.filter(elem => elem.name === name).length > 0;
    }

    static GetEndOfPaymentDate (state: StoreState): Date {
        const chosenTariff = state.applicantData.chosenTariff;
        if (!chosenTariff) {
            return new Date();
        }
        let endOfPaymentAge = chosenTariff.name.startsWith('C03') ? parseInt(chosenTariff.name.substring(3)) : 85;
        // @ts-ignore
        const age = state.applicantData.age;
        const yearsToPay = endOfPaymentAge - age;
        const fiscalYear = state.applicantData.birthYear + age;
        const endOfPaymentYear = fiscalYear + yearsToPay;
        let endOfPaymentDay, endOfPaymentMonth;

        if (state.applicantData.birthMonth === 1 ||
            state.applicantData.birthMonth === 2 || state.applicantData.birthMonth === 3) {
            endOfPaymentDay = '31';
            endOfPaymentMonth = '03';
        } else if (state.applicantData.birthMonth === 4 ||
            state.applicantData.birthMonth === 5 || state.applicantData.birthMonth === 6) {
            endOfPaymentDay = '30';
            endOfPaymentMonth = '06';
        } else if (state.applicantData.birthMonth === 7 ||
            state.applicantData.birthMonth === 8 || state.applicantData.birthMonth === 9) {
            endOfPaymentDay = '30';
            endOfPaymentMonth = '09';
        } else {
            endOfPaymentDay = '31';
            endOfPaymentMonth = '12';
        }
        return new Date(endOfPaymentYear + '-' + endOfPaymentMonth + '-' + endOfPaymentDay);
    }

    static getOneTimePayValue (state: StoreState) {
        if (state.applicantData.chosenTariff && state.applicantData.chosenTariff.name === 'E03') {
            return NumberUtil.toLocalizedNumberString(state.calculatedSum);
        }
        return '';
    }
}
