export default class NumberUtil {
    /**
     * Returns the specified number converted to a localized string capped at
     * 2 fraction digits
     * @param input
     */
    public static toLocalizedNumberString (input: number): string {
        return input.toLocaleString(
            'de-DE',
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        );
    }

    public static toLocalizedNumberStringNoFractions (input: number): string {
        return input.toLocaleString(
            'de-DE'
        );
    }
}
